/* TableComponent.css */

/* Container */
.table-container {
  background-color: var(--background-color);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 0 16px;
  /* Remove top and bottom padding */
  margin: 16px 0;
  height: 250px;
  /* Adjust as needed */
  overflow-x: auto;
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Responsive widths */

@media (min-width:240px) and (max-width: 600px) {
  .table-container {
    width: 70vw;
  }
}

@media (min-width: 601px) {
  .table-container {
    width: 90vw;
    /* 90% of the device's screen width */
  }
}

@media (min-width: 601px) {
  .table-container {
    width: 568px;
    /* 768px - 200px */
    width: 60vw;
  }
}

@media (min-width: 800px) {
  .table-container {
    width: 600px;
    /* 800px - 200px */
    width: 60vw;
  }
}

@media (min-width: 820px) {
  .table-container {
    width: 620px;
    /* 820px - 200px */
    width: 63vw;
  }
}

@media (min-width: 834px) {
  .table-container {
    width: 634px;
    /* 834px - 200px */
    width: 70vw;
  }
}

@media (min-width: 1280px) {
  .table-container {
    width: 1080px;
    /* 1280px - 200px */
    width: 75vw;
  }
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  /* Adjust padding for smaller fonts */
  text-align: left;
  font-size: 12px;
  /* Smaller font size */
  border-bottom: 1px solid var(--border-color);
}

thead th {
  background-color: var(--header-background-color);
  color: var(--text-color);
  font-weight: 600;
  position: sticky;
  /* Make the header sticky */
  top: 0;
  /* Stick to the top */
  z-index: 1;
  /* Ensure it is above other content */
}

tbody tr:hover {
  background-color: var(--secondary-color);
  color: var(--background-color);
}

/* Navigation */

.nav h3 {
  color: var(--primary-color);
  margin: 0;
}

.nav p {
  margin: 0;
  font-size: 14px;
  color: var(--text-color);
}

.right-align {
  text-align: right;
}

.p0 {
  padding: 0;
}

.m4 {
  margin: 4px;
}