@media only screen and (min-width: 360px) {

  .App {
    text-align: center;
    color: #fff;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .top {
      .logout-button {
  top: 20px;
}

    .logo {
      font-weight: bold;
      text-transform: uppercase;
    }

    .logout {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 16px;
    }

    a, span {
      text-decoration: none;
      font-size: 0.85em;
      color: inherit;
    }
  }

  .navigation a, 
.navigation div {
  padding: 16px;
}

  .body {
    display: grid;
    min-height: 300px;
    font-size: 14px;
  }

  .aside {
    background-color: rgb(36, 37, 37);
    color: white;
    scrollbar-color: #a9a9a9 transparent;

    .aside-tile {
      display: grid;
      align-items: center;
      height: 40px;
      border-top: 1px solid #434343;
    }
  }

  .main {
    display: grid;
    grid-area: main;
    grid-template-rows: auto 1fr auto;
    overflow: scroll;
  }

  .header.tab {
    padding: 0;

    h2,
    h3 {
      padding: 0px;
      margin: 8px 16px;
      height: 40px;
      display: grid;
      align-items: center;
    }

    .nav {
      padding: 0.1%;
      background-color: rgb(19, 19, 19);
      color: white;
    }
  }

  .header.report {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }

  .container.nav {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0 0.1%;
    color: white;
    justify-content: start;
    gap: 4px;

    >span.update-btn {
      width: 65px;
      text-align: center;
      border-radius: var(--rad-4);
      padding: 8px 12px;
      background-color: #303030;
      font-size: .8rem;
      font-weight: bold;

      svg {
        margin-right: 4px;
        width: 10px;
      }
    }
  }

  .content {
    padding: 6px;
  }

  .footer {
    padding: 16px;
    border-top: 1px solid;
    background: #f9f9f9;
    display: grid;
    align-items: end;
  }

  .grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  .grid-2-col {
    display: grid;
    grid-template-rows: auto auto;
  }

  .grid-2-col.nav {
    grid-template-columns: auto auto;
    display: grid;
    align-items: center;
    /* padding: 4px 8px; */
    margin-bottom: 16px;
    justify-content: space-between;
    background-color: unset;
    color: inherit;

    h2,
    h3,
    h4 {
      margin: 0;
    }

    svg {
      margin: 0 6px;
    }

    .right-content {
      padding: 0;
    }
  }

  .body.section .grid-2-col.nav {
    margin: 0;

    .grid-2-col.nav {
      margin: 0;
      gap: 4px;
    }
  }

  .grid-3-col {
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 5px;
  }

  .grid-4-col {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
  }

  .grid-4-rows {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
  }

  .left-content {
    display: grid;
    justify-content: start;

    h4,
    p,
    span {
      margin: 0;
    }
  }

  .right-content {
    display: grid;
    justify-content: end;
    padding-right: 16px;
  }


  /* TABLES */

  /* .table-container {
    width: 100%;
    max-width: 320px;
    height: 230px;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
  } */

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
    padding: 10px;
    font-size: .8rem;
    text-align: left;
  }

  thead,
  th {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  table th {
    padding: 4px 8px;
    color: rgb(72, 72, 72);
  }


  table,
  thead,
  tbody,
  th,
  td,
  tr {
    margin-bottom: 2px;
  }

  table td {
    padding: 5px 24px 0 6px;
    color: rgb(68, 68, 68);
    white-space: normal;
  }

  th.user-id,
  td.user-id {
    width: 50px;
    text-wrap: nowrap;
    overflow: hidden;
  }

  th.username,
  td.username {
    width: 50px;
    text-wrap: nowrap;
    overflow: hidden;
  }

  /* Styling for odd rows */
  tr:nth-child(odd) {
    background-color: rgba(179, 179, 179, 0.05);
  }

  thead th {
    color: #222222;
  }

  /* Hover effect for table rows */
  tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }


  thead tr {
    margin-bottom: 10px;
  }

  td {
    padding: 0px;
  }

  /* Styling for table cells */
  td {
    border: none;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    /* padding-left: 50%; */
  }

  /* Add before pseudo-element for table cells */
  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    font-size: 12px;
    /* Decreased font size for smaller screens */
  }

  .container {
    border-radius: var(--rad-4);
  }

  /* Spacing */
  .p0 {
    padding: 0;
  }

  .p4 {
    padding: 4px;
  }

  .m0 {
    margin: 0;
  }

  .m4 {
    margin: 4px;
  }

  .m8 {
    margin: 8px;
  }

  .m16 {
    margin: 16px;
  }

  .tile {
    display: grid;
    height: 80px;
    align-items: center;
    padding:12px;
    line-height: 10px;
    color: #303030;
    font-size: .875rem;
    font-weight: bold;

    .main-stat {
      display: block;
      font-weight: bold;
      font-size: 1.5rem;
      color: #181818;
      margin: 12px 0;

      p {
        margin: 5px;
      }
    }

    .round-bg {
      border-radius: var(--rad-50);
      height: 20px;
      width: 20px;
    

      svg {
        color: #fff;
        height: 10px;
        width: 10px;
      }
    }
  }

  .section {
    margin-bottom: 24px;
  }
  
  .graph {

    h3 {
      margin: 8px;
    }

    canvas {
      /* height: 120px !important;
      width: 285px !important; */
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: var(--primary-color);
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .filter-button,
  .clear-filter-button,
  .update-chart-button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .filter-button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
  }

  .clear-filter-button {
    background-color: var(--red);
    color: #fff;
    border: none;
    border-radius: var(--rad-4);
  }

  .update-chart-button {
    background-color: var(--green);
    color: #fff;
    border: none;
    border-radius: var(--rad-4);
  }

  /* Select component styles */
  .select-container {
    width: 120px;
  }

  .select__control {
    border: 1px solid #ddd;
    border-radius: var(--rad-4);
  }

  .select__control:hover {
    border-color: #aaa;
  }

  .select__menu {
    border: 1px solid #ddd;
    border-top: none;
    border-radius: var(--rad-4);
    z-index: 99;
  }

  .select__option {
    padding: 10px;
  }

  .select__option--is-selected {
    background-color: #f0f0f0;
  }

  .select__option--is-focused {
    background-color: #e0e0e0;
  }

  /* Form styles */
  .form-container {
    width: 285px;
    background-color: #fff;
    border-radius: var(--rad-8);
  }

  .form label {
    display: block;
    margin-bottom: 16px;
  }

  form {
    margin-bottom: 16px;
    padding: 32px 20px;
  }

  .form input,
  .form button,
  .form select {
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: var(--rad-8);
  }

  .form input {
    width: 86%;
  }

  .form button {
    background-color: var(--primary-color);
    color: #fff;
  }

  .form select {
    appearance: none;
    /* Remove default arrow */
    -webkit-appearance: none;
    /* Remove default arrow for Safari */
    -moz-appearance: none;
    /* Remove default arrow for Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23ddd" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"><path d="M7 10l5 5 5-5z"/></svg>');
    /* Custom arrow icon */
    background-repeat: no-repeat;
    background-position: right 10px center;
  }

  /* Button styles */
  .button-container {
    text-align: center;
    margin-top: 20px;
  }

  .refresh-button {
    cursor: pointer;

    :hover {
      color: var(--hover-btn);
    }
  }

  .btn {
    background-color: var(--primary-color);
    color: #fff;
    padding: 6px 12px;
    margin-right: 4px;
    border-radius: var(--rad-4);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btn:hover {
    background-color: #0056b3;
  }

  .changeStats {
    color: white;
    font-weight: bold;
    background-color: var(--green);
    padding: 2px 4px;
  }

  /* Colors */
  /* 
 */

  .silver {
    background-color: #ededed;
  }

  .gray {
    background: gray;
  }

  .gray-txt {
    color: gray;
  }

  .green {
    background: var(--green);
  }

  .green-txt {
    color: var(--green);
  }

  .red {
    background: var(--red);
  }

  .blue {
    background: var(--primary-color);
  }

  .yellow {
    background: var(--yellow);
  }

}