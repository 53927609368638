/* Media query for larger screens */

@media only screen and (min-width: 600px) {
  .grid-2-col {
    grid-template-rows: unset;
    grid-template-columns: auto auto;
  }

  .grid-3-col {
    grid-template-rows: unset;
    grid-template-columns: auto auto auto;

    .chart-container {
      width: 25vw;
    }
  }

  .grid-4-col {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }

  .grid-4-rows {
    display: grid;
    grid-template-rows: auto auto auto auto;
  }

}