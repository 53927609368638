.chart-container {
  /* width: 100% !important; */
  height: 100% !important;
  padding: 16px !important;
  box-sizing: border-box !important;
  position: relative;
  /* background-color: red; */
}

.chart {
  width: 100% !important;
  height: 100% !important;
}

/* Widths for different breakpoints */

@media (max-width: 600px) {
  .chart-container {
    width: 90vw;
  }
}

@media (min-width: 601px) {
  .chart-container {
    width: 60vw;
  }
}

@media (min-width: 800px) {
  .chart-container {
    width: 34vw;
  }
}

@media (min-width: 820px) {
  .chart-container {
    width: 34vw;
  }
}

@media (min-width: 834px) {
  .chart-container {
    width: 39vw;
  }
}

@media (min-width: 1280px) {
  .chart-container {
    width: 40vw;
  }
}



@media (max-width: 240px) {
  .chart-container {
    height: 150px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 241px) and (max-width: 320px) {
  .chart-container {
    height: 150px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 321px) and (max-width: 359px) {
  .chart-container {
    height: 150px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .chart-container {
    height: 150px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .chart-container {
    height: 150px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 390px) and (max-width: 392px) {
  .chart-container {
    height: 180px !important; /* Reduced by 10% from 250px */
  }
}

@media (min-width: 393px) and (max-width: 413px) {
  .chart-container {
    height: 180px !important; /* Reduced by 10% from 300px */
  }
}

@media (min-width: 414px) and (max-width: 429px) {
  .chart-container {
    height: 180px !important; /* Reduced by 10% from 300px */
  }
}

@media (min-width: 430px) and (max-width: 767px) {
  .chart-container {
    height: 180px !important; /* Reduced by 10% from 300px */
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .chart-container {
    height: 200px !important; /* Reduced by 10% from 400px */
  }
}

@media (min-width: 800px) and (max-width: 819px) {
  .chart-container {
    height: 200px !important; /* Reduced by 10% from 400px */
  }
}

@media (min-width: 820px) and (max-width: 833px) {
  .chart-container {
    height: 200px !important; /* Reduced by 10% from 400px */
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  .chart-container {
    height: 200px !important; /* Reduced by 10% from 400px */
  }
}

@media (min-width: 1280px) {
  .chart-container {
    height: 200px !important; /* Reduced by 10% from 500px */
  }
}
