@import "Root.css";
@import "240px.css";
@import "360px.css";
@import "600px.css";

/* Mobile first */

@media only screen and (min-width: 240px) {

  /* App.css */
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
    background-color: whitesmoke;
  }

  html,
  body {
    height: 100%;
  }

  .italic {
    font-style: italic;
  }

  header,
  main,
  .footer {
    padding: 16px;
  }

  .ckhks90 {
    padding: 0;

    >div {
      padding: 16px;
    }
  }

  .section {
    margin-bottom: 16px;
  }

  .sticky {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
  }

  .app {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    height: 100vh;
    height: auto;
    height: 100%;
  }

  .menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .navigation {
    display: none;
    background-color: #333;
    position: absolute;
    top: 70px;
    left: 0;
    width: 200px;
    z-index: 1000;
  }

  .navigation.open {
    display: block;
  }

  .navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .navigation li {
    border-bottom: 1px solid #555;
  }

  .navigation a,
  .navigation div {
    display: block;
    padding: 12px;
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  .navigation a:hover,
  .navigation div:hover {
    background-color: #555;
  }

  .logout-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    /* Adjust font size to fit the icon */
    cursor: pointer;
    padding: 5px 10px;
    position: absolute;
    /* Absolute positioning for top-right corner */
    right: 10px;
    /* Adjust positioning */
    top: 10px;
    /* Adjust positioning */
  }

  .logout-button i {
    font-size: 20px;
    /* Adjust icon size if necessary */
  }

  .content {
    padding: 16px;
  }

  .grid.section {
    h3 {
      /* margin: 0; */
    }
  }

  .p16 {
    padding: 16px;
  }



  /* Input styling */
  .input-group,
  .button-group {
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-columns: 50% 50%;
    /* gap: 16px; */
    width: 90%;
    width: 250px;
    padding: 0 16px;
    position: static;
    margin-bottom: 16px;

    .left-content {
      display: grid;
      justify-content: start;
      height: 50px;
    }

    .right-content {
      display: grid;
      justify-content: end;
      padding-right: unset;
      height: 50px;
    }
  }

  .input-group label.text {
    font-size: 0.75rem;
    color: var(--primary-color);
    font-weight: 700;
    position: relative;
    top: 0.5rem;
    margin: 0 0 0 7px;
    padding: 0 3px;
    background: #f2f2f2;
    width: fit-content;
  }

  .input-group input.input,
  .button-group button {
    display: block;
    padding: 11px 10px;
    width: 100%;
    font-size: 0.75rem;
    font-weight: bold;
    color: #1f1f1f;
    border: 2px var(--primary-color) solid;
    border-radius: 5px;
    background: #f2f2f2;
  }

  .input-group input.input.disabled {
    width: 100%;
  }

  .input-group input.input:focus,
  .button-group button:focus {
    outline: none;
  }

  .button-group {
    /* width: 100%; */
    grid-template-columns: auto;
    margin-top: 16px;
  }

  .button-group button {
    cursor: pointer;
    margin-top: 7px;
    border: 2px var(--primary-color) solid;
    background-color: var(--primary-color);
    color: #f2f2f2;
  }

  #companyAddon {
    width: 100px;
  }

  /* End of Input Styling */

  /* Twin-put */
  .twinput {
    display: flex;
    align-items: center;
    display: grid;
    grid-template-columns: 65% 35%;
    width: 120px;

    .input#returnToPlayer {
      display: block;
      /* padding: 11px 10px; */
      /* padding: unset; */
      /* width: 75px; */
      height: 15px;
      font-size: 0.75rem;
      font-weight: bold;
      color: #1f1f1f;
      border: 1px var(--primary-color) solid;
      border-radius: 5px 0 0 5px;
      background: #f2f2f2;
    }

    .button--submit {
      display: block;
      padding: 11px 10px;
      width: 25px;
      font-size: 0.75rem;
      font-weight: bold;
      color: #f1f1f1;
      background-color: rgb(31, 39, 70);
      border: none;
      border-radius: 0 5px 5px 0;
    }

    .button--submit:hover {
      background-color: #4b73d7;
    }

    .input:focus,
    .input:focus-visible {
      border-color: #3898EC;
      outline: none;
    }
  }

  .small-text {
    font-size: 0.75rem;
    font-style: italic;
  }
}

@media only screen and (min-width: 360px) {

  .input-group,
  .button-group {
    width: 90%;
  }

  /* .table-container {
    max-width: 290px;
    height: 250px;
  } */

  .form-container {
    width: 290px;
  }
}

/* Tablet and larger screens */
@media only screen and (min-width: 600px) {
  .ckhks90 {
    overflow: auto;
    display: grid;
    align-content: stretch;
    grid-template-rows: 1fr auto;
  }

  .section {
    /* margin-bottom: 32px; */
  }

  .header.section {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: -10px;
    background-color: white;
    height: 50px;
  }

  .header.top {
    grid-template-columns: 1fr auto;
    /* Adjust layout for larger screens */
    position: relative;
    /* Reset positioning for header */
    padding-right: 50px;
    /* Extra padding to accommodate the logout button */
    height: unset;
    align-items: start;
  }

  .menu-button {
    display: none;
    /* Hide the menu button on larger screens */
  }

  .app {
    grid-template-columns: 200px 1fr;
    /* Add a column for the aside */
  }

  .navigation {
    display: block;
    /* Show the menu as aside on larger screens */
    position: static;
    /* Change to static positioning */
    width: auto;
    /* Allow the width to adapt */
    height: 100vh;
    /* Make the nav take full height */
    background-color: inherit;
    overflow-y: auto;
    /* Allow scrolling if menu content overflows */
  }

  .navigation ul {
    display: flex;
    /* Display menu items in a row */
    flex-direction: column;
  }

  .navigation a,
  .navigation div {
    padding: 15px;
    color: white;
  }

  .navigation a:hover,
  .navigation div:hover {
    background-color: #555;
  }

  .logout-button {
    position: fixed;
    /* Fixed positioning for logout button */
    right: 10px;
    /* Align to the far right */
    top: 10px;
    /* Align to the top */
    background-color: #333;
    /* Match the header background */
    z-index: 1000;
    /* Ensure it appears above other elements */
  }

  .content {
    padding: 0px;
    height: calc(100vh - 60px);
    /* Adjust height to take full screen minus header */
  }

  .tile {
    height: 90px;
  }

  /* .table-container {
    max-width: unset;
    height: 300px;
  } */

  .form-container {
    width: 100%;
  }

  .form input {
    width: 93%;
  }

  .input-group,
  .button-group {
    width: 90%;
  }
}