.chat {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 6px;
  width: 100%;
  margin: 0 auto;
  padding: 4px;
  height: 100%;
  border-radius: 12px;
  /* background-color: beige; */
  transition: max-height 0.5s ease-in-out;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: space-between;
  align-items: normal;
  overflow-y: auto;
  height: 200px;
  margin-bottom: 16px;
}

.message {
  display: grid;
  align-items: center;
  border-bottom: 1px solid #333;
  font-size: var(--m-small-font-size);
  background: rgb(230, 230, 230);
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  line-height: 1.2;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 5px 10px;
}

.message .strong {
  color: var(--primary-color);
  font-weight: 700;
}

.message span {
  flex: 1;
  word-break: break-word;
  color: black;
  text-align: left;
}

.message-text {
  color: black;
  text-align: left;
  font-size: var(--m-small-font-size);
  margin-bottom: 4px;
}

.message-text.time {
  font-size: .7rem;
  font-style: italic;
  text-align: right;
  margin-right: 12px;
  color: var(--brand-orange);
}

.message-input {
  display: flex;
  align-items: center;
}

.message-input input {
  flex: 1;
  border: 1px solid #282c34;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  color: black;
  font-size: var(--small--font-size);
  padding: 6px 12px;
  border-radius: 6px;
}

.message-input input::placeholder {
  color: black;
  font-style: italic;
  font-size: var(--small-font-size);
}

.message-input button {
  background: var(--primary-color);
  color: white;
  font-weight: bold;
  border: none;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  padding: 6px 12px;
  border-radius: 6px;
}

.message-input button:hover {
  background-color: var(--primary-color);
}

/* .right-align {
  text-align: right !important;
  margin-right: 12px !important;
} */