/* Material Design Colors */
:root {
  --primary-color: #0e6ac9;
  --secondary-color: lightgreen;
  --background-color: whitesmoke;
  --text-color: #000000;
  --border-color: #e0e0e0;
  --header-background-color: #f5f5f5;
  --shadow-color: rgba(0, 0, 0, 0.2);
}

* {
  --rad-0: 0;
  --rad-4: 4px;
  --rad-8: 8px;
  --rad-16: 16px;
  --rad-50: 50px;

  /* Colors */
  --hover-btn: rgb(117, 117, 118);
  --blue: #0e6ac9;
  --green: lightgreen;
  --red: #dc3545;
  --yellow: #ad9a16;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.border {
  border: 1px solid #aeaeae8c;
  border-radius: var(--rad-4);
}

.shadow {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

  /* Spacing */
  .p0 {
    padding: 0;
  }

  .p4 {
    padding: 4px;
  }

  .m0 {
    margin: 0;
  }

  .m4 {
    margin: 4px;
  }

  .m8 {
    margin: 8px;
  }

  .m16 {
    margin: 16px;
  }

    /* Colors */
  /* 
 */

 .silver {
  background-color: #ededed;
}

.gray {
  background: gray;
}

.gray-txt {
  color: gray;
}

.green {
  background: var(--green);
}

.green-txt {
  color: var(--green);
}

.red {
  background: var(--red);
}

.blue {
  background: var(--primary-color);
}

.yellow {
  background: var(--yellow);
}